<template>
  <div>
    <b-modal
      id="modal-input-sumber-dana"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form @submit.prevent>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Sumber Dana <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_sumber_dana')"
            type="text"
            v-model="$v.is_data.nama_sumber_dana.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-sumber-dana')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import _ from 'lodash'

export default {
  name: "modalInput",
  props: [ "fileName" ],
  data() {
    return {
      is_data: {
        nama_sumber_dana: "",
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_sumber_dana: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.status == 200) {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input-sumber-dana");
            this.resetModal();
          } else {
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            vm.button = "Simpan";
            vm.busy = false;
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_sumber_dana: "",
      };
    },
  },
};
</script>
